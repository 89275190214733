import React from 'react'
import { withPrefix } from 'gatsby'

import Layout from '../layouts/home'
import SEO from '../components/seo'

export default function IndexPage() {

  return <Layout>
    <SEO
      title="Ceremony Nepal | Partner Network"
      ogTitle="Ceremony Nepal | Partner Network"
      keywords={[]}
      description="Join our partner program to gain customer leads, get reports and insights about current market trends and demands, be seen in our marketing campaigns and get featured for free till the end of 2020"
      meta={[
        {
          property: 'og:url',
          content: `http://www.ceremonynepal.com/partner-network`
        },
        {
          property: 'og:image',
          content: `http://www.ceremonynepal.com/images/partner-network.png`
        }
      ]}
    />
    <section class="slider d-flex align-items-center" style={{background: "#fff"}}>
      <div class="container">
        <div class="row d-flex justify-content-center text-center">
          <div class="col-md-12">
            <img src={withPrefix('/images/partner-network.png')} alt="Ceremony Nepal Partner Network" width="70%" />
            <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfuX61IEHS9WlExSoS-nlFDib8Kbj9ZQH-U7CsqOYjJ-tT4dA/viewform?embedded=true" width="640" height="1500" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
          </div>
        </div>
      </div>
    </section>
  </Layout>

}
